import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { DialogModule } from '@lis-ui/dialog';
import { DialogInfoComponent } from './dialog-info.component';

@NgModule({
  declarations: [DialogInfoComponent],
  imports: [CommonModule, DialogModule, ButtonModule],
  exports: [DialogInfoComponent],
})
export class DialogInfoModule {}
