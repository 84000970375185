import { LisCreateUserResponse, LisUser } from '@lis-types';

const LIS_MOCK_USER_GET: LisUser = {
  id: '2',
  firstName: 'John',
  lastName: 'Doe',
  emailAddress: 'john.doe9@netural.com',
  globalRole: 'PARTNER',
  language: 'de',
  currentLogin: null,
  lastLogin: null,
  defaultLocationId: null,
  partners: [
    {
      partnerId: '230483',
      name: 'TO DO',
      role: 'ADMIN',
      locations: [
        {
          locationId: '230483',
          name: 'BATYSASHYKZHOL',
          country: 'KZ',
          city: 'URALSK',
        },
      ],
    },
    {
      partnerId: '230380',
      name: 'TO DO',
      role: 'ADMIN',
      locations: [
        {
          locationId: '230380',
          name: 'GLASS COM, OOO',
          country: 'RU',
          city: "POSELOK ZAOZER'JE, GUREVSKY RAION,",
        },
      ],
    },
    {
      partnerId: '230239',
      name: 'Internorm',
      role: 'ADMIN',
      locations: [
        {
          locationId: '230239',
          name: 'EUROPEN ENDÜSTRI İNŞ. SAN. VE TIC.',
          country: 'TR',
          city: 'ESKİŞEHİR',
        },
      ],
    },
    {
      partnerId: '230232',
      name: 'TO DO',
      role: 'MEMBER',
      locations: [
        {
          locationId: '23023201',
          name: 'IP IVCHENKO',
          country: 'RU',
          city: 'KHAVAROVSKY KRAY',
        },
        {
          locationId: '230232',
          name: 'BUSINESS ALLIANCE',
          country: 'RU',
          city: 'KHABAROVSKIY KRAY',
        },
      ],
    },
  ],
};

const LIS_MOCK_SUPERUSER_GET: LisUser = {
  id: '2',
  firstName: 'John',
  lastName: 'Doe',
  emailAddress: 'john.doe9@netural.com',
  globalRole: 'SUPERADMIN',
  language: 'de',
  currentLogin: null,
  lastLogin: null,
  defaultLocationId: '230380',
  partners: null,
};

const LIS_MOCK_AGENT_GET: LisUser = {
  id: '2',
  firstName: 'John',
  lastName: 'Doe',
  emailAddress: 'john.doe9@netural.com',
  globalRole: 'AGENT',
  language: 'de',
  currentLogin: null,
  lastLogin: null,
  defaultLocationId: '230380',
  partners: null,
};

export const LIS_MOCK_USER = LIS_MOCK_AGENT_GET;

export const LIS_MOCK_USER_CREATE: LisCreateUserResponse = {
  id: '2',
  firstName: 'John',
  lastName: 'Doe',
  emailAddress: 'john.doe9@netural.com',
  status: 'INVITED',
  role: 'ADMIN',
  locations: [
    {
      locationId: '23023201',
      name: 'IP IVCHENKO',
      country: 'RU',
      city: 'KHAVAROVSKY KRAY',
    },
    {
      locationId: '230232',
      name: 'BUSINESS ALLIANCE',
      country: 'RU',
      city: 'KHABAROVSKIY KRAY',
    },
  ],
};
