import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomTitleResolver extends TitleStrategy {
  private subscriptions = new Subscription();
  constructor(
    private translateService: TranslateService,
    private readonly title: Title
  ) {
    super();
  }

  public override updateTitle(snapshot: RouterStateSnapshot): void {
    const titleKey = this.buildTitle(snapshot);

    if (!titleKey) {
      return;
    }

    if (this.translateService.currentLang) {
      this.title.setTitle(this.translateService.instant(titleKey));
    }

    this.subscriptions.add(
      this.translateService.onLangChange.subscribe(() => {
        this.title.setTitle(this.translateService.instant(titleKey));
      })
    );
  }
}
