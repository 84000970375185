import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { DialogModule } from '@lis-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRefreshApplicationComponent } from './dialog-refresh-application.component';

@NgModule({
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  declarations: [DialogRefreshApplicationComponent],
  exports: [DialogRefreshApplicationComponent],
})
export class DialogRefreshApplicationModule {}
