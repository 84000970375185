import { LisDocumentUploadResponse } from '@lis-types';

export const LIS_MOCK_DOCUMENT_UPLOAD: LisDocumentUploadResponse = [
  {
    statusCode: 200,
    fileName: 'file1.pdf',
  },
  {
    statusCode: 200,
    fileName: 'file2.pdf',
  },
  {
    statusCode: 409,
    fileName: 'file3.pdf',
  },
  {
    statusCode: 409,
    fileName: 'file42.pdf',
  },
  {
    statusCode: 413,
    fileName: 'file43.pdf',
  },
  {
    statusCode: 413,
    fileName: 'file44.pdf',
  },
  {
    statusCode: 413,
    fileName: 'file45.pdf',
  },
  {
    statusCode: 413,
    fileName: 'file46.pdf',
  },
  {
    statusCode: 500,
    fileName: 'file6.pdf',
  },
];
