import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { DialogModule } from '@lis-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationModule } from '@lis-ui/notification';
import { FormModule } from '../../form/ui/form.module';
import { DialogLocationDefaultComponent } from './dialog-location-default.component';

@NgModule({
  declarations: [DialogLocationDefaultComponent],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    FormModule,
    NotificationModule,
  ],
  exports: [DialogLocationDefaultComponent],
})
export class DialogLocationDefaultModule {}
