import { Component, OnInit } from '@angular/core';
import { LIS_TOAST_TYPES, LisToast, LisToastType } from '@lis-types';
import { Toast } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lis-toast-global',
  templateUrl: './toast-global.component.html',
  styleUrls: ['./toast-global.component.scss'],
})
export class ToastGlobalComponent extends Toast implements OnInit {
  public toast?: LisToast;

  ngOnInit(): void {
    const item = Object.entries(LIS_TOAST_TYPES).find(
      ([, value]) => value === this.toastPackage.toastType
    );
    const type = item ? (item[0] as LisToastType) : 'info';

    this.toast = {
      type,
      title: this.title ?? '',
      subtitle: this.message ?? undefined,
    };
  }
}
