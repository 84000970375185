import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { DialogModule } from '@lis-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { DropListModule } from '@lis-ui/drop-list';
import { NotificationModule } from '@lis-ui/notification';
import { FormModule } from '../../form/ui/form.module';
import { DialogColumnChangeComponent } from './dialog-column-change.component';

@NgModule({
  declarations: [DialogColumnChangeComponent],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    FormModule,
    NotificationModule,
    DropListModule,
  ],
  exports: [DialogColumnChangeComponent],
})
export class DialogColumnChangeModule {}
