import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LIS_ENVIRONMENT } from '@lis-environment';
import { LanguageService, LoginService, TokenService } from '@lis-services';
import { cloneDeep } from 'lodash-es';
import { Observable, catchError, of } from 'rxjs';

import {
  LIS_MOCK_DOCUMENT_UPLOAD,
  LIS_MOCK_PUBLIC_MACHINE,
  LIS_MOCK_USER,
  LIS_MOCK_USER_CREATE,
} from './mock';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private languageService: LanguageService,
    private loginService: LoginService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // const apiUrl = LIS_ENVIRONMENT.api.baseUrl;
    const language = this.languageService.getLang();
    const useMockdata = LIS_ENVIRONMENT.mock;

    // const authUrls = [apiUrl];

    // const isApiUrl = !!authUrls.find((url) => request.url.startsWith(url));

    // if (!isApiUrl) {
    //   // logout if token endpoint returns bad request
    //   if (request.url.endsWith('/token')) {
    //     // refetch token if its expired
    //     return next.handle(request).pipe(
    //       catchError((error: HttpErrorResponse) => {
    //         console.log('error', error);

    //         if (error.status === 400) {
    //           this.authService.logout();
    //         }
    //         throw error;
    //       })
    //     );
    //   }

    //   return next.handle(request);
    // }

    if (useMockdata) {
      // if (request.url.endsWith('/token')) {
      //   const simulatedError: HttpErrorResponse = new HttpErrorResponse({
      //     status: 499,
      //   });

      //   // refetch token if its expired
      //   return throwError(simulatedError);
      // }

      if (request.url.endsWith('/user')) {
        return of(
          new HttpResponse({
            status: 200,
            body: cloneDeep(LIS_MOCK_USER),
          })
        );
      }

      if (request.url.endsWith('/user/defaultLocation')) {
        return of(
          new HttpResponse({
            status: 200,
            body: void 0,
          })
        );
      }

      if (request.url.endsWith('/userAdministration')) {
        return of(
          new HttpResponse({
            status: 200,
            body: cloneDeep(LIS_MOCK_USER_CREATE),
          })
        );
      }

      if (request.url.includes('/userAdministration/users/')) {
        return of(
          new HttpResponse({
            status: 200,
            body: request.body,
          })
        );
      }

      if (
        request.url.includes('/documentManagement/documents/') &&
        request.method === 'PUT'
      ) {
        return of(
          new HttpResponse({
            status: 200,
            body: void null,
          })
        );
      }

      if (
        request.url.includes('/documentManagement/documents/') &&
        request.method === 'DELETE'
      ) {
        return of(
          new HttpResponse({
            status: 200,
            body: void 0,
          })
        );
      }

      if (request.url.endsWith('/user/language')) {
        return of(
          new HttpResponse({
            status: 200,
            body: void 0,
          })
        );
      }

      if (request.url.endsWith('/userAdministration/users/accept')) {
        return of(
          new HttpResponse({
            status: 200,
            body: void 0,
          })
        );
      }

      if (
        request.url.includes('/documentManagement/products/') &&
        request.url.endsWith('/documents')
      ) {
        return of(
          new HttpResponse({
            status: 200,
            body: LIS_MOCK_DOCUMENT_UPLOAD,
          })
        );
      }

      if (request.url.includes('/machine/')) {
        return of(
          new HttpResponse({
            status: 200,
            body: LIS_MOCK_PUBLIC_MACHINE,
          })
        );
      }
    }

    if (request.url.indexOf('assets/i18n/') > -1) {
      return next.handle(request);
    }
    if (request.url.indexOf('/openid-configuration') > -1) {
      return next.handle(request);
    }

    const token = this.tokenService.token$$.getValue();

    if (!token) {
      console.warn('No Token Provided', request.url);
      return next.handle(request);
    }

    let _request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });

    if (language) {
      const languageHeaders = {
        de: 'de-DE',
        en: 'en-EN',
      };
      const languageHeader = languageHeaders[language] || 'de-DE';

      _request = _request.clone({
        headers: _request.headers.set('Accept-Language', languageHeader),
      });
    }

    // refetch token if its expired
    return next.handle(_request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);

        if (error.status === 401 && !LIS_ENVIRONMENT.ignoreLoginRequest) {
          this.loginService.login();
        }
        throw error;
      })
    );
  }
}
