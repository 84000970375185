import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { LIS_ENVIRONMENT } from '@lis-environment';
import {
  LIS_THEME_DARK_CSS_CLASS,
  LIS_THEME_LOCALSTORAGE_KEY,
} from '@lis-helpers';
import {
  AuthService,
  IconsRegistry,
  ThemeService,
  TokenService,
  TrackingService,
  UpdateService,
  UserService,
} from '@lis-services';
import { LisThemeMode, completeIconSet } from '@lis-types';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { StoryblokService } from './shared/storyblok/services/storyblok.service';

@Component({
  selector: 'lis-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private authService: AuthService,
    private iconRegistry: IconsRegistry,
    private tokenService: TokenService,
    private userService: UserService,
    private metaService: Meta,
    private sbService: StoryblokService,
    private trackingService: TrackingService,
    private updateService: UpdateService
  ) {
    this.updateService.init();
    window.storyblok.init();
    window.storyblok.on(['change', 'published'], function () {
      location.reload();
    });
  }

  ngOnInit(): void {
    this.listenForQueryParams();
    this.initApplication();
  }

  private listenForQueryParams(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        const queryParams =
          this.router.getCurrentNavigation()?.finalUrl?.queryParams;

        this.sbService.init(queryParams || {});
      }
    });
  }

  private async initApplication(): Promise<void> {
    this.initMetaTags();
    this.authService.init(LIS_ENVIRONMENT);
    this.iconRegistry.registerIcons(completeIconSet);
    this.initGoogleTagManager();
    this.listenForThemeChanges();
    this.listenForToken();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initMetaTags(): void {
    const content =
      LIS_ENVIRONMENT.env === 'dev' || LIS_ENVIRONMENT.env === 'qa'
        ? 'noindex, nofollow'
        : 'index, follow';
    this.metaService.addTag({ name: 'robots', content });
  }

  private initGoogleTagManager(): void {
    if (LIS_ENVIRONMENT.googleTagId) {
      this.trackingService.enable();
    }

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        this.trackingService.pushTag(gtmTag);
      }
    });
  }

  private listenForThemeChanges(): void {
    if (
      !(LIS_THEME_LOCALSTORAGE_KEY in localStorage) ||
      !localStorage.getItem(LIS_THEME_LOCALSTORAGE_KEY)
    ) {
      this.themeService.set('system');
    } else {
      this.themeService.set(
        localStorage.getItem(LIS_THEME_LOCALSTORAGE_KEY) as LisThemeMode
      );
    }

    this.subscriptions.add(
      this.themeService.theme$
        .pipe(distinctUntilChanged())
        .subscribe((themeMode) => {
          this.themeService.set(themeMode);
          let mode = themeMode;
          if (themeMode === 'system') {
            mode = window.matchMedia('(prefers-color-scheme: dark)').matches
              ? 'dark'
              : 'light';
          }
          document.documentElement.classList[
            mode === 'dark' ? 'add' : 'remove'
          ](LIS_THEME_DARK_CSS_CLASS);
        })
    );
  }

  private listenForToken(): void {
    this.subscriptions.add(
      this.tokenService.token$.subscribe((token) => {
        this.userService.handleNewAccessToken(token);
      })
    );
  }
}
