import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { LIS_ENVIRONMENT } from '@lis-environment';
import { AppModule } from './app/app.module';

registerLocaleData(de);

if (LIS_ENVIRONMENT.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
