import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropListItemModule } from '@lis-ui/drop-list-item';
import { IconModule } from '@lis-ui/icon';
import { DropListComponent } from './drop-list.component';

@NgModule({
  declarations: [DropListComponent],
  imports: [CommonModule, DropListItemModule, IconModule, DragDropModule],
  exports: [DropListComponent],
})
export class DropListModule {}
