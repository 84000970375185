import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LIS_TOAST_TYPES } from '@lis-types';
import { IconModule } from '@lis-ui/icon';
import { ToastModule } from '@lis-ui/toast';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { ToastGlobalComponent } from './toast-global.component';

@NgModule({
  declarations: [ToastGlobalComponent],
  imports: [
    CommonModule,
    IconModule,
    ToastModule,
    ToastNoAnimationModule.forRoot({
      toastComponent: ToastGlobalComponent,
      toastClass: 'lis-toast-global',
      iconClasses: LIS_TOAST_TYPES,
      timeOut: 7000,
      tapToDismiss: false,
      newestOnTop: false,
    }),
  ],
})
export class ToastGlobalModule {}
