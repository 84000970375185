import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogColumnChangeModule } from './dialog-column-change';
import { DialogConfirmationModule } from './dialog-confirmation';
import { DialogInfoModule } from './dialog-info';
import { DialogLocationDefaultModule } from './dialog-location-default';
import { DialogRefreshApplicationModule } from './dialog-refresh-application';

@NgModule({
  imports: [
    CommonModule,
    DialogInfoModule,
    DialogLocationDefaultModule,
    DialogConfirmationModule,
    DialogColumnChangeModule,
    DialogRefreshApplicationModule,
  ],
})
export class DialogAllModule {}
