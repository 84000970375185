import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lis-ui/button';
import { DialogModule } from '@lis-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogConfirmationComponent } from './dialog-confirmation.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonModule, DialogModule],
  declarations: [DialogConfirmationComponent],
  exports: [DialogConfirmationComponent],
})
export class DialogConfirmationModule {}
