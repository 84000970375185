import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { DialogAllModule } from '@lis-dialogs/dialog-all.module';
import { LIS_ENVIRONMENT } from '@lis-environment';

import { provideServiceWorker } from '@angular/service-worker';
import { ToastGlobalModule } from '@lis-ui/toast-global/toast-global.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DirectivesModule } from './shared/directives/directives.module';
import { HttpRequestInterceptor } from './shared/interceptors/interceptor';
import { CustomTitleResolver } from './shared/resolver/custom-title.resolver';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: LIS_ENVIRONMENT.googleTagId ?? null,
    }),
    AppRoutingModule,
    DialogAllModule,
    NoopAnimationsModule,
    DirectivesModule,
    OAuthModule.forRoot(),
    ToastGlobalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: TitleStrategy,
      useClass: CustomTitleResolver,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
})
export class AppModule {}
